import { Antrag } from "./antrag";
import { Antragsteller } from './antragsteller';
import { Bevollmaechtigter } from './bevollmaechtigter';
import {OzgPerson} from "./ozgPerson";


export class AntragWithXmlData {

  constructor(public antragsteller: Antragsteller,
    public bevollmaechtigter: Bevollmaechtigter,
    public ozgPerson: OzgPerson,
    public antragData: Antrag,
    public foerderbetrag: string,
    public editAntragsteller: boolean,
    public postfach: string,
    public ozgServiceType: string) {
  }
}
