import {Moment} from 'moment';
import {Antrag} from "./data/antrag";
import {MatTableDataSource} from "@angular/material/table";
import {AccountOverview} from "./data/account.overview";

const KENNZEICHEN_MAP = new Map();

KENNZEICHEN_MAP.set('BEGEM-FV', 'BEG EM');
KENNZEICHEN_MAP.set('BEGEM2-FV', 'BEG EM');
KENNZEICHEN_MAP.set('BEGPT-FV', 'BEG PT');
KENNZEICHEN_MAP.set('EBN-FV', 'EBN');
KENNZEICHEN_MAP.set('EBW-FV', 'EBW');

const KENNZEICHEN_KEY_MAP = new Map();

KENNZEICHEN_KEY_MAP.set('BEG EM', 'BEGEM-FV');
KENNZEICHEN_KEY_MAP.set('BEG EM', 'BEGEM2-FV');
KENNZEICHEN_KEY_MAP.set('BEG PT', 'BEGPT-FV');
KENNZEICHEN_KEY_MAP.set('EBN', 'EBN-FV');
KENNZEICHEN_KEY_MAP.set('EBW', 'EBW-FV');

export const dateFormatDe = 'dd.MM.yyyy';
export const dateTimeFormatDe = dateFormatDe + ' HH:mm:ss';

export const toUtcMoment = (date: Moment): Moment => {
  if (date == null) {
    return null;
  }
  return date.utc(true);
};

export const setKenzeichenAgregateTypesForAntrag = (antrags: Antrag[]): MatTableDataSource<any> => {
  const data = antrags;
  data.forEach(x => {
    x.kennzeichenText = KENNZEICHEN_MAP.get(x.kennzeichen);
  });

  return new MatTableDataSource(data);
};

export const setKenzeichenAgregateTypesForAccount = (accounts: AccountOverview[]): MatTableDataSource<any> => {
  const data = accounts;
  data.forEach(x => {
    if (x.formularTypes) {
      var kenzeichens = new Array<String>;
      x.formularTypes.forEach(
        type => {
          kenzeichens.push(KENNZEICHEN_MAP.get(type));
        }
      )
      x.formularTypesText = Array.from(new Set(kenzeichens));
    }
  });
  return new MatTableDataSource(data);
}

export const getKenzeichenAgregateTypesForUserProfile = (types: string[]): Array<any> => {
  var kenzeichens = new Array<String>;
  if (types == null) {
    return;
  }
  types.forEach(
    type => {
      kenzeichens.push(KENNZEICHEN_MAP.get(type));
    }
  )
  return Array.from(new Set(kenzeichens));
}

export const getKenzeichenAgregateKeys = (types: string[]): Array<any> => {
  var kenzeichens = new Array<String>;
  if (types == null) {
    return;
  }
  types.forEach(
    type => {
      if (type == 'BEG EM') {
        kenzeichens.push('BEGEM-FV');
        kenzeichens.push('BEGEM2-FV');
      } else {
        kenzeichens.push(KENNZEICHEN_KEY_MAP.get(type));
      }
    }
  )
  return Array.from(new Set(kenzeichens));
}

