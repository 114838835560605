
<mat-card class="mat-elevation-z0">
  <!-- title -->
  <mat-card-title>Möchten Sie Ihre persönlichen Daten ändern ?</mat-card-title>
  <mat-card-content>

    <div style="padding-top: 20px" fxLayout fxLayoutGap="130px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
    <mat-radio-group color="primary" [(ngModel)]="digitalAutentifizierung" disabled="{{data.editAntragsteller && data.antragData.isBevollmaechtigter == true}}">
      <mat-radio-button style="padding-right: 5px" [value]="false" aria-label="Ja, manuell">Ja, manuell</mat-radio-button><br>
      <mat-radio-button style="padding-right: 5px" [value]="true" aria-label="Ja, über digitale Authentifizierung">Ja, über digitale Authentifizierung</mat-radio-button>
<!--      <label *ngIf="this.data.editAntragsteller" style="color:orange"> <br/> Eine digitale Authentifizierung ist zur Zeit nicht möglich.</label>-->
    </mat-radio-group>
    </div>

    <div style="padding-top: 20px" fxLayout fxLayoutGap="60px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
    <label>Ich bin eine: </label>
    <mat-radio-group color="primary"  [(ngModel)]="privatPerson" disabled="{{(data.editAntragsteller && data.antragData.isBevollmaechtigter == true) || !digitalAutentifizierung}}">
      <mat-radio-button style="padding-right: 5px; margin-left: 15px" [value]="true" aria-label="Privatperson">Privatperson</mat-radio-button>
      <mat-radio-button style="padding-right: 5px; margin-left: 15px" [value]="false" aria-label="Oraganisation">Organisation</mat-radio-button>
    </mat-radio-group>
    </div>
  </mat-card-content>
<mat-dialog-actions align="start" >
  <div style="padding-top: 2%" fxLayout fxLayoutGap="20px" fxLayout.xs="row" fxLayoutAlign.xs="start start">
  <button mat-raised-button (click)="close()" class="close-button">Schließen</button>

    <button *ngIf="!digitalAutentifizierung"  mat-raised-button (click)=" confirmManual()" class="close-button">Manuell bearbeiten</button>
    <button *ngIf="digitalAutentifizierung && privatPerson"  mat-raised-button (click)="confirm()" class="close-button">Authentifizierung (als antragstellende Person)</button>
    <button *ngIf="digitalAutentifizierung &&!privatPerson" mat-raised-button (click)="confirm()" class="close-button">Authentifizierung über ELSTER Mein Unternehmenskonto</button>


  </div>
</mat-dialog-actions>
</mat-card>
