import {Component, EventEmitter, Inject, Input, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {BackendService} from "../shared/backend.service";
import {AntragWithXmlData} from "../shared/data/antragWithXmlData";
import {Router} from "@angular/router";

@Component({
  selector: 'confirm-personal-data-auth',
  templateUrl: './confirm-personal-data-auth.html',
  styleUrls: ['./confirm-personal-data-auth.component.css'],
})
export class ConfirmPersonalDataAuth {
  digitalAutentifizierung = true;
  privatPerson = true;
  isLoadingResults = false;

  @Output() manual: EventEmitter<boolean> = new EventEmitter();
  constructor(
    private dialogRef: MatDialogRef<ConfirmPersonalDataAuth>,
    private backend: BackendService,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: AntragWithXmlData) {
  }

  ngOnInit(): void {
    if (this.data.editAntragsteller == true) {
      this.digitalAutentifizierung = false;
    }
  }

  close(): void {
    this.dialogRef.close('close');
  }

  confirm(): void {
    if (this.digitalAutentifizierung) {
      this.isLoadingResults = true;
      this.backend.getOzgServiceAuthentication(this.data.antragData.kennzeichen, this.data.antragData.vorgangsnummer, this.privatPerson)
        .subscribe(data => window.location.replace(data.toString()));
    }
  }

    confirmManual(): void {
      if (!this.digitalAutentifizierung) {
        this.dialogRef.close('confirm');
    }
  }
}
